import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
export default function AboutBox() {
  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          sanityAbout {
            profileImage {
              asset {
                url
                gatsbyImageData(
                  backgroundColor: "#f9f9f9"
                  placeholder: NONE
                  width: 340
                )
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <section className="aboutBox">
            <div className="innerAboutBox">
              <GatsbyImage
                loading="lazy"
                className="aboutBoxImage"
                image={data.sanityAbout.profileImage.asset.gatsbyImageData}
                objectPosition="top"
                alt="Hej Jeg er Mathilde og jeg er skaberen bag Gastrologik.dk"
              />
              <div className="textWrapper">
                <h3>Hej! Jeg er Mathilde!</h3>
                <p>
                  Velkommen til Gastrologik.dk - min hjemmeside, der hjælper dig
                  med at danne overblik, give dig økonomisk rådgivning og
                  tidsbesparende rutiner, samt at forøge din kreativitet, når du
                  laver mad. Hjemmesidens enkelthed gør det nemt og
                  overskueligt, at finde ud af, hvor mange ingredienser, som du
                  har brug for.
                </p>
              </div>
            </div>
          </section>
        )
      }}
    />
  )
}
