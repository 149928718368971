import React from "react"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"

import { graphql } from "gatsby"
import SinglePost from "../components/global/SinglePost"
import PostGrid from "../components/global/PostGrid"
import AboutBox from "../components/global/AboutBox"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
export default function Frontpage({ data }) {
  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Gastrologik",
    url: "https://gastrologik.dk/",
    potentialAction: {
      "@type": "SearchAction",
      target: {
        "@type": "EntryPoint",
        urlTemplate: "https://gastrologik.dk/soeg?q={search_term_string}",
      },
      "query-input": "required name=search_term_string",
    },
  }

  return (
    <Layout>
      <SearchEngineOptimizaion
        title={
          data.sanityPages.seo.seoTitle !== ""
            ? data.sanityPages.seo.seoTitle
            : "Gatrologik | Mad med mening"
        }
        description={
          data.sanityPages.seo.description !== ""
            ? data.sanityPages.seo.description
            : "Gastrologik er en hjemmeside, der hjælper dig med at danne overblik, give dig økonomisk rådgivning og tidsbesparende rutiner, samt at forøge din kreativitet, når du laver mad."
        }
        schemaMarkup={schema}
      />

      <section className="index">
        <div className="titleWrapper">
          <h1>{data.sanityPages.pageTitle}</h1>
          <div className="spanLine"></div>
          <BlockContent
            blocks={data.sanityPages._rawBody}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
            className="recipeBlockContent"
          />
        </div>
        <div className="titleLine">
          <h2>Nyeste opskfift</h2>
        </div>
        <SinglePost post={data.newestRecipe.edges[0].node} />
        <div className="titleLine">
          <h2>Nyeste opskrifter</h2>
        </div>
        <PostGrid posts={data.newestRecipes.nodes} />
        <AboutBox />

        <div className="titleLine">
          <h2>Nyeste blogopslag</h2>
        </div>
        <PostGrid posts={data.newestPosts.nodes} />

        <div className="titleLine">
          <h2>Inspiration til morgenbordet</h2>
        </div>
        <PostGrid posts={data.breakfast.nodes} />
        <div className="titleLine">
          <h2>Godt til ganen</h2>
        </div>
        <PostGrid posts={data.cocktails.nodes} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query frontPageQuery {
    newestRecipe: allSanityRecipes(sort: { publishedAt: DESC }, limit: 1) {
      edges {
        node {
          _rawBody
          title

          slug {
            current
          }
          recipeImage {
            verticalImage {
              asset {
                gatsbyImageData(width: 340, placeholder: NONE, formats: AVIF)
              }
            }
          }
          time
        }
      }
    }
    newestRecipes: allSanityRecipes(
      sort: { publishedAt: DESC }
      limit: 8
      skip: 1
      filter: { tags: { elemMatch: { value: { eq: "aftensmad" } } } }
    ) {
      nodes {
        ...generalRecipeInformation
      }
    }
    breakfast: allSanityRecipes(
      sort: { publishedAt: DESC }
      limit: 8
      skip: 1
      filter: { tags: { elemMatch: { value: { eq: "morgenmad" } } } }
    ) {
      nodes {
        ...generalRecipeInformation
      }
    }
    cocktails: allSanityRecipes(
      sort: { publishedAt: DESC }
      limit: 8
      skip: 1
      filter: { tags: { elemMatch: { value: { eq: "cocktails" } } } }
    ) {
      nodes {
        ...generalRecipeInformation
      }
    }

    sanityPages(slug: { current: { eq: "index" } }) {
      pageTitle
      _rawBody
      seo {
        seoTitle
        seoDescription
      }
    }

    newestPosts: allSanityPost(sort: { publishedAt: DESC }, limit: 8) {
      nodes {
        title
        slug {
          current
        }
        image: mainImage {
          asset {
            gatsbyImageData(
              width: 280
              height: 366

              placeholder: NONE
              backgroundColor: "#f9f9f9"
              formats: AVIF
            )
          }
        }
      }
    }
  }

  fragment generalRecipeInformation on SanityRecipes {
    title

    slug {
      current
    }
    image: recipeImage {
      verticalImage {
        asset {
          gatsbyImageData(
            width: 280
            height: 366

            placeholder: NONE
            backgroundColor: "#f9f9f9"
            formats: AVIF
          )
        }
      }
    }
  }
`
