import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ExtractText from "../sanity/ExtractText"
import ChevronRightIcon from "../assets/icons/ChevronRightIcon"

export default function SinglePost({ post }) {
  return (
    <div className="singlePost">
      <div>
        <Link to={"/" + post.slug.current + "/"}>
          <GatsbyImage
            loading="lazy"
            className="singlePostImage postGridImage"
            alt={"Nyeste opskrift | " + post.title}
            image={post.recipeImage.verticalImage.asset.gatsbyImageData}
          />
        </Link>
      </div>
      <div className="textWrapper">
        <Link to={"/" + post.slug.current + "/"}>{post.title}</Link>
        <div className="spanLineLeft"></div>
        <Link to={"/" + post.slug.current + "/"}>
          {ExtractText(post._rawBody)}
        </Link>
        <Link to={"/" + post.slug.current + "/"}>
          Læs mere <ChevronRightIcon />
        </Link>
      </div>
    </div>
  )
}
